<template>
	<div style="background: white;width: 1200px; margin: 10px auto;">
		<div class="banner">
			<img src="../../assets/img/RCservice.png" />
		</div>
		<div class="service">

			<div class="title">临海人才网简介</div>
			<div class="serviceCont">
				临海人才网是浙江知名的专业人才网站。网站是由人力资源、人才开发领域的专业人士和IT领域的专业人士运用当前先进的经营模式进行运作。
				网络招聘是公司核心业务，围绕这一核心业务，公司全方位为企业客户提供多样化的产品与服务，整合人才招聘解决方案，满足不同客户不同阶段的招聘需求，为客户创造最大的价值。为企业抛出合适人才，促进企业成长发展；为个人提供工作机会、协助规划美好职业生涯；并为人力资源经理创造高效的资讯交流平台，解决人力资源方面实际问题。临海人才网：企业的人才之源，个人的职业伙伴。
			</div>

			<!-- <div class="Map banner">
				<img src="../../assets/map.png" />
			</div>

			<div class="serviceMap">
				<font>临海市人才市场管理办公室、临海市人才市场、《中国临海人才》官方网站：</font>
				<br>地址：浙江省台州市临海市大洋街道河阳路319号
				<br>邮编：317000&nbsp;
			</div> -->
		</div>
		<div class="honor">
			<div class="title">收费标准</div>
			<div class="honorCont">
				<PayTable></PayTable>
			</div>
		</div>
		<div class="honor">
			<div class="title">联系我们</div>
			<div class="honorCont">
				<div class="honorflex">
					<div class="ibox bt1 br1">
						<div class="subTitle">临海市人才交流中心</div>
						<div>
							人事代理、档案管理<br>
							0576-85159677<br>
							0576-85288196
						</div>
					</div>
					<div class="ibox bt1">
						<div class="subTitle">临海市人才市场</div>
						<div>
							现场招聘、大屏发布<br>
							0576-85159089<br>
						</div>
					</div>
					
					<div class="ibox bt1 br1">
						<div class="subTitle">《中国临海人才》官方网站</div>
						<div>
							网络招聘、资讯发布<br />
							0576-85159028
						</div>
					</div>
					<div class="ibox bt1">
						<div class="subTitle">大中专毕业生就业指导中心</div>
						<div>
							0576-85159677
						</div>
					</div>
					
					
					<div class="ibox bt1 br1">
						<div class="subTitle">劳动监察</div>
						<div>
							0576-85289551
						</div>
					</div>
					<div class="ibox bt1">
						<div class="subTitle">劳动仲裁</div>
						<div>
							0576-85289551
						</div>
					</div>
					
					<div class="ibox bt1 br1">
						<div class="subTitle">劳动关系</div>
						<div>
							0576-85315793
						</div>
					</div>
					<div class="ibox bt1">
						<div class="subTitle">事业管理</div>
						<div>
							客服热线：0576-85108193
						</div>
					</div>
					
					<div class="ibox br1 bt1">
						<div class="subTitle">工资福利</div>
						<div>
							0576-85319558
						</div>
					</div>
					<div class="ibox bt1">
						<div class="subTitle">专业技术</div>
						<div>
							0576-85117030
						</div>
					</div>
					<div class="ibox br1 bt1">
						<div class="subTitle">考培中心</div>
						<div>
							0576-85288978
						</div>
					</div>
					<div class="ibox bt1">
						<div class="subTitle">就业服务</div>
						<div>
							0576-85159265
						</div>
					</div>
					
					
					
					<div class="ibox br1">
						<div class="subTitle">社保中心</div>
						<div>
							综合科：0576-85308560<br>
							参保科：0576-85157523<br>
							结算科：0576-85191360
						</div>
					</div>
					<div class="ibox">
						<div class="subTitle">人才派遣</div>
						<div>
							新世纪：0576-85157261<br>
							雷  博：0576-85157289
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import PayTable from "@/components/PayTable.vue";
	import {
		Getnewsbyid,
	} from "@/api/home";
	import utils from "@/assets/js/utils";
	export default {
		components: {
			PayTable,
		},
		data() {
			return {
				serviceinfo: {},
				honorinfo: {},
			};
		},
		created() {
			//this.getservice();
			//this.gethonor();
		},
		methods: {
			getservice() {
				var _this = this;
				var par = {
					id: 3186
				};
				Getnewsbyid(par).then((res) => {
					if (res.success) {
						_this.serviceinfo = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			gethonor() {
				var _this = this;
				var par = {
					id: 2785
				};
				Getnewsbyid(par).then((res) => {
					if (res.success) {
						_this.honorinfo = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	// banner
	.banner {
		width: 100%;

		img {
			width: 100%;
		}
	}

	// 企业产品与服务
	.service-c {
		width: 100%;
		padding: 20px 0;
	}

	.service {
		padding: 38px 40px;

		.title {
			font-size: 26px;
			font-weight: bold;
			color: #101010;
			font-family: 'SourceHanSansSC-regular';
		}

		.serviceCont {
			margin-top: 20px;
			font-size: 14px;
			color: #898989;
			line-height: 1.5;
		}

		.Map {
			padding: 20px 0;
		}

		.serviceMap {
			font-family: 'SourceHanSansSC-regular';
			font-size: 14px;
			color: #898989;
			line-height: 1.5;

			font {
				color: #101010
			}
		}
	}

	// 部分所获荣誉
	.honor {
		padding: 40px;
		box-sizing: border-box;

		.title {
			line-height: 48px;
			border-left: 5px solid #0089ff;
			background-color: #eee;
			font-size: 18px;
			color: #0089FF;
			padding-left: 32px;
		}

		.honorCont {
			font-size: 14px;
			color: #333
		}

		.honorflex {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			padding: 15px 0;
		}

		.ibox {
			font-family: 'SourceHanSansSC-regular';
			width: 50%;
			box-sizing: border-box;
			padding: 30px 20px;
			color: #898989;

			.subTitle {
				color: #101010;
				margin-bottom: 15px;
			}
		}

		.bt1 {
			border-bottom: 1px solid #eee;
		}

		.br1 {
			border-right: 1px solid #eee;
		}
	}
</style>
